.place-visit-card-section {
    width: 500px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 40px;
    border-radius: 20px;
    transition: all 0.5s ease;
}

.place-visit-card-section:hover {
    transform: translateY(-10px);
}

.place-visit-card-section .place-visit-card-image {
    width: 100%;
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.place-visit-card-section .place-visit-card-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: all 0.5s ease;
}

.place-visit-card-section .place-visit-card-image img:hover {
    transform: scale(1.2);
}

.place-visit-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.place-visit-card-content h2 {
    text-transform: uppercase;
}

.place-visit-card-content p {
    font-weight: 500;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 628px) {
    .place-visit-card-section {
        width: 100%;
        height: fit-content;
    }

    .place-visit-card-section .place-visit-card-image {
        height: 200px;
    }

    .place-visit-card-section .place-visit-card-image img {
        height: 100%;
    }
}