.virtual-tour-video-section {
    width: 100%;
    height: 300px;
    background-image: url("./video-bg.webp");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.shadow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0px 80px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-transform: uppercase;
}

.shadow>i {
    font-size: 100px;
    cursor: pointer;
}

.shadow h2 {
    font-size: 30px;
    font-weight: 700;
}

.shadow>div>h2:first-child {
    color: #ffb607;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .shadow {
        padding: 0px 20px;
    }

    .shadow>i {
        font-size: 50px;
    }

    .shadow h2 {
        font-size: 20px;
    }

    .popup-image frame {
        width: 100%;
    }
}