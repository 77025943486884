a {
    color: #fff;
}

li {
    list-style: none;
}

input[type="checkbox"] {
    display: none;
}

.active {
    /* background-color: #b81104; */
    color: #b81104;
    border-radius: 5px;
}

/* Top Banner */
.top-banner {
    width: 100%;
    height: 50px;
    background-color: #480607;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 0px 20px;
}

.top-banner-left {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.phone,
.email {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.top-banner-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 20px;
}

/* Navbar Section */
.navbar {
    width: 100%;
    height: 80px;
    background-color: #fff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.5s ease-in;
}

.navbar.activated {
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 98;
}

.navbar .logo {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.logo h2 {
    font-weight: 800;
    text-transform: uppercase;
}

.navbar .logo>img {
    width: 50px;
    object-fit: cover;
}

.navbar .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all 0.5s ease-in;
    z-index: 10;
}

.navbar .menu li {
    cursor: pointer;
    font-weight: 700;
    padding: 5px 8px;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
}

.menu li:hover {
    background-color: #b81104;
    color: #fff;
    border-radius: 5px;
}

.book {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.book button {
    background-color: #b81104;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    border: 1.5px solid #b81104;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.book button:hover {
    background-color: #fff;
    color: #000;
    border: 1.5px solid #b81104;
}

.menu-icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    display: none;
}

input[type="checkbox"]:checked~.menu {
    display: block;
}

/* Responsive */

/* Top Banner */
@media (min-width: 320px) and (max-width: 768px) {
    .top-banner {
        display: none;
    }

    .logo h2 {
        font-size: 18px;
    }

    .navbar {
        height: 60px;
        padding: 5px;
    }
}

/* Navbar Section */
@media (min-width: 320px) and (max-width: 900px) {
    .menu-icon {
        display: block;
    }

    .menu li {
        margin-top: 40px;
        margin-left: 0%;
    }

    .navbar .menu {
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #fff;
        flex-direction: column;
        text-align: center;
        width: 100%;
        height: 100vh;
        color: #000;
        gap: 50px;
        padding: 0px;
        display: none;
    }

    .book button {
        display: none;
    }

}

@media (min-width: 480px) and (max-width: 900px) {
    .menu-icon {
        display: block;
    }

    .menu li {
        margin-top: 40px;
        margin-left: 0%;
    }

    .navbar .menu {
        position: fixed;
        top: 60px;
        right: 0;
        background-color: #480607;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        color: #fff;
        gap: 50px;
        padding: 0px;
        display: none;
    }

    .book button {
        display: none;
    }
}