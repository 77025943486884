.place-to-visit-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

.place-to-visit-section>h2 {
    text-transform: uppercase;
    font-weight: 800;
}

.places-to-visit-container {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
}