.hero {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero img {
    width: 100%;
    height: 100vh;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff;
    background-color: #b81104;
    padding: 30px 20px;
    border-radius: 10px;
}

.swiper-button-next:active,
.swiper-button-prev:active {
    color: #fff;
}

.slider-card {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-size: cover;
}

.slider-card1 {
    background-image: url('./slider-1.webp');
}

.slider-card2 {
    background-image: url('./slider-2.webp');

}

.slider-card3 {
    background-image: url('./slider-3.webp');

}

.slider-card4 {
    background-image: url('./slider-4.webp');

}

.slider-card .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0px 80px;
    background-color: rgba(0, 0, 0, 0.5);
}

.slider-card .overlay h2 {
    font-size: 30px;
    text-align: center;
}

.slider-card .overlay p {
    max-width: 800px;
    text-align: center;
}

.slider-card .overlay button {
    font-size: 15px;
    padding: 10px 20px;
    border: none;
    background-color: #b81104;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.slider-card .overlay button:hover {
    transform: translateY(-5px);
}

/* Responsive */
/*  768px and 920px */
@media (min-width: 768px) and (max-width: 920px) {
    .slider-card .overlay {
        padding: 0px;
    }

    .slider-card .overlay p {
        padding: 0px 80px;
    }
}

/*  628px and 768px */
@media (min-width: 628px) and (max-width: 768px) {
    .slider-card .overlay {
        padding: 0px;
    }

    .slider-card .overlay p {
        padding: 0px 80px;
    }
}

/*  320px and 628px */
@media (min-width: 320px) and (max-width: 628px) {

    .hero {
        height: 450px;
    }

    .slider-card {
        height: 450px;
        width: 100%;
        /* border: 2px solid #000; */
    }

    .slider-card .overlay {
        padding: 0px;
    }

    .slider-card .overlay h2 {
        font-size: 20px;
        padding: 0px 20px;
    }

    .slider-card .overlay p {
        padding: 0px 20px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        visibility: hidden;
    }
}