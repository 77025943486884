.customers-counting-card-section {
    width: 90%;
    height: 150px;
    position: absolute;
    color: #000;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px;
    border-radius: 10px;
    bottom: -120px;
}

.customers-counting-card-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
}

.customers-counting-card-box:not(:first-child) {
    border-left: 1.5px solid rgba(0, 0, 0, 0.24);
    padding-left: 20px;
}

.customers-counting-card-box h2 {
    font-size: 3rem;
    color: #b81104;
}

.customers-counting-card-box h4 {
    color: #898989;
}

.customers-counting-card-box h2,
.customers-counting-card-box h4 {
    font-weight: 800;
    text-align: start;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 1024px) {
    .customers-counting-card-section {
        height: fit-content;
        flex-wrap: wrap;
        align-items: start;
        justify-content: space-between;
    }

    .customers-counting-card-box:not(:first-child) {
        border: none;
        padding: 0px;
    }
}

@media (min-width: 320px) and (max-width: 628px) {
    .customers-counting-card-section {
        bottom: -200px;
    }
}